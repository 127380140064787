// @flow
import {
    DASHBOARD_HITS,
    DASHBOARD_HITS_SUCCESS,
    DASHBOARD_HITS_FAILED
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    hits: null
};

type DashboardAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, hits?: {} | null};

const DashboardHits = (state:State = INIT_STATE, action: DashboardAction) => {
    switch (action.type) {
        case DASHBOARD_HITS:
            return { ...state, loading: true };
        case DASHBOARD_HITS_SUCCESS:
            return { ...state, hits: action.payload, loading: false, error: null };
        case DASHBOARD_HITS_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default DashboardHits;