// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    GENERAL_BASIC_INFO,
    GENERAL_BASIC_INFO_SAVE,
    GENERAL_CONTACT_INFO,
    GENERAL_CONTACT_INFO_SAVE,
    GENERAL_BUSINESS_INFO,
    GENERAL_BUSINESS_INFO_SAVE,
    GENERAL_REGIONAL,
    GENERAL_REGIONAL_SAVE
} from '../../constants/actionTypes';


import {
    generalBasicInfoSuccess,
    generalBasicInfoFailed,
    generalContactInfoSuccess,
    generalContactInfoFailed,
    generalBusinessInfoSuccess,
    generalBusinessInfoFailed,
    generalRegionalSuccess,
    generalRegionalFailed
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}

function* gereralbasicinfo({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/basicinfo`, options);
        if(response['status']){
            yield put(generalBasicInfoSuccess(response['data']));
        }
        else{
            yield put(generalBasicInfoFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(generalBasicInfoFailed(message));
    }
}

function* gereralbasicinfosave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/basicinfo`, options);
        if(response['status']){
            yield put(generalBasicInfoSuccess(response['data']));
        }
        else{
            yield put(generalBasicInfoFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(generalBasicInfoFailed(message));
    }
}

function* gereralcontactinfo({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/contactinfo`, options);
        if(response['status']){
            yield put(generalContactInfoSuccess(response['data']));
        }
        else{
            yield put(generalContactInfoFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(generalContactInfoFailed(message));
    }
}

function* gereralcontactinfosave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/contactinfo`, options);
        if(response['status']){
            yield put(generalContactInfoSuccess(response['data']));
        }
        else{
            yield put(generalContactInfoFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(generalContactInfoFailed(message));
    }
}

function* gereralbusinessinfo({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/businessinfo`, options);
        if(response['status']){
            yield put(generalBusinessInfoSuccess(response['data']));
        }
        else{
            yield put(generalBusinessInfoFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(generalBusinessInfoFailed(message));
    }
}

function* gereralbusinessinfosave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/businessinfo`, options);
        if(response['status']){
            yield put(generalBusinessInfoSuccess(response['data']));
        }
        else{
            yield put(generalBusinessInfoFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(generalBusinessInfoFailed(message));
    }
}

function* gereralregional({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/regional`, options);
        if(response['status']){
            yield put(generalRegionalSuccess(response['data']));
        }
        else{
            yield put(generalRegionalFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(generalRegionalFailed(message));
    }
}

function* gereralregionalsave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/regional`, options);
        if(response['status']){
            yield put(generalRegionalSuccess(response['data']));
        }
        else{
            yield put(generalRegionalFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(generalRegionalFailed(message));
    }
}

export function* watchGeneralBasicInfo():any {
    yield takeEvery(GENERAL_BASIC_INFO, gereralbasicinfo);
}

export function* watchGeneralBasicInfoSave():any {
    yield takeEvery(GENERAL_BASIC_INFO_SAVE, gereralbasicinfosave);
}

export function* watchGeneralContactInfo():any {
    yield takeEvery(GENERAL_CONTACT_INFO, gereralcontactinfo);
}

export function* watchGeneralContactInfoSave():any {
    yield takeEvery(GENERAL_CONTACT_INFO_SAVE, gereralcontactinfosave);
}

export function* watchGeneralBusinessInfo():any {
    yield takeEvery(GENERAL_BUSINESS_INFO, gereralbusinessinfo);
}

export function* watchGeneralBusinessInfoSave():any {
    yield takeEvery(GENERAL_BUSINESS_INFO_SAVE, gereralbusinessinfosave);
}

export function* watchGeneralRegional():any {
    yield takeEvery(GENERAL_REGIONAL, gereralregional);
}

export function* watchGeneralRegionalSave():any {
    yield takeEvery(GENERAL_REGIONAL_SAVE, gereralregionalsave);
}

function* generalSaga():any {
    yield all([
        fork(watchGeneralBasicInfo),
        fork(watchGeneralBasicInfoSave),
        fork(watchGeneralContactInfo),
        fork(watchGeneralContactInfoSave),
        fork(watchGeneralBusinessInfo),
        fork(watchGeneralBusinessInfoSave),
        fork(watchGeneralRegional),
        fork(watchGeneralRegionalSave),
    ]);
}

export default generalSaga;