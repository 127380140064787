// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    SEO_GOOGLE_VERIFY,
    SEO_GOOGLE_VERIFY_SAVE
} from '../../constants/actionTypes';


import {
    seoGoogleVerifySuccess,
    seoGoogleVerifyFailed
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}

function* seogoogleverify({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/googleverify`, options);
        if(response['status']){
            yield put(seoGoogleVerifySuccess(response['data']));
        }
        else{
            yield put(seoGoogleVerifyFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(seoGoogleVerifyFailed(message));
    }
}

function* seogoogleverifysave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/googleverify`, options);
        if(response['status']){
            yield put(seoGoogleVerifySuccess(response['data']));
        }
        else{
            yield put(seoGoogleVerifyFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(seoGoogleVerifyFailed(message));
    }
}

export function* watchSeoGoogleVerify():any {
    yield takeEvery(SEO_GOOGLE_VERIFY, seogoogleverify);
}

export function* watchSeoGoogleVerifySave():any {
    yield takeEvery(SEO_GOOGLE_VERIFY_SAVE, seogoogleverifysave);
}


function* seoSaga():any {
    yield all([
        fork(watchSeoGoogleVerify),
        fork(watchSeoGoogleVerifySave),
    ]);
}

export default seoSaga;