// @flow
import {
    INTEGRATIONS_CHATWIDGET,
    INTEGRATIONS_CHATWIDGET_SUCCESS,
    INTEGRATIONS_CHATWIDGET_FAILED,
    INTEGRATIONS_CHATWIDGET_SAVE
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    chatwidget: null
};

type IntegrationsAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, chatwidget?: {} | null, saving?: boolean};

const IntegrationsChatWidget = (state:State = INIT_STATE, action: IntegrationsAction) => {
    switch (action.type) {
        case INTEGRATIONS_CHATWIDGET:
            return { ...state, loading: true };
        case INTEGRATIONS_CHATWIDGET_SAVE:
            return { ...state, saving: true };
        case INTEGRATIONS_CHATWIDGET_SUCCESS:
            return { ...state, chatwidget: action.payload, loading: false, saving: false, error: null };
        case INTEGRATIONS_CHATWIDGET_FAILED:
            return { ...state, error: action.payload, loading: false, saveing: false };
        default: return { ...state };
    }
}

export default IntegrationsChatWidget;