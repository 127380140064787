// @flow
import {
    DASHBOARD_DISK_USAGE,
    DASHBOARD_DISK_USAGE_SUCCESS,
    DASHBOARD_DISK_USAGE_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    diskusage: null
};

type DashboardAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, diskusage?: {} | null};

const Dashboard = (state:State = INIT_STATE, action: DashboardAction) => {
    switch (action.type) {
        case DASHBOARD_DISK_USAGE:
            return { ...state, loading: true };
        case DASHBOARD_DISK_USAGE_SUCCESS:
            return { ...state, diskusage: action.payload, loading: false, error: null };
        case DASHBOARD_DISK_USAGE_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default Dashboard;