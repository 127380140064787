// @flow
import {
    DASHBOARD_BROWSER,
    DASHBOARD_BROWSER_SUCCESS,
    DASHBOARD_BROWSER_FAILED
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    browser: null
};

type DashboardAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, browser?: {} | null};

const DashboardBrowser = (state:State = INIT_STATE, action: DashboardAction) => {
    switch (action.type) {
        case DASHBOARD_BROWSER:
            return { ...state, loading: true };
        case DASHBOARD_BROWSER_SUCCESS:
            return { ...state, browser: action.payload, loading: false, error: null };
        case DASHBOARD_BROWSER_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default DashboardBrowser;