import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from './helpers/authUtils';

// lazy load all the views
const Dashboard = React.lazy(() => import('./pages/dashboards/'));
const Projects = React.lazy(() => import('./pages/dashboards/projects'));
const General = React.lazy(() => import('./pages/dashboards/general'));
const Hosting = React.lazy(() => import('./pages/dashboards/hosting'));
const Editor = React.lazy(() => import('./pages/dashboards/editor'));
const SEO = React.lazy(() => import('./pages/dashboards/seo'));
const Form = React.lazy(() => import('./pages/dashboards/form'));
const Integrations = React.lazy(() => import('./pages/dashboards/integrations'));
const Builder = React.lazy(() => import('./pages/site/builder'));
const Startup = React.lazy(() => import('./pages/site/startup'));

// auth
const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('./pages/account/ForgetPassword'));
const Register = React.lazy(() => import('./pages/account/Register'));
const ConfirmAccount = React.lazy(() => import('./pages/account/Confirm'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {
    const isAuthTokenValid = isUserAuthenticated();
    if (!isAuthTokenValid) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }

    const loggedInUser = getLoggedInUser();
    // check if route is restricted by role
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
      // role not authorised so redirect to home page
      return <Redirect to={{ pathname: '/' }} />
    }

    // authorised so return component
    return <Component {...props} />
  }} />
)

const routes = [
  // auth and account
  { path: '/login', name: 'Login', component: Login, route: Route },
  { path: '/logout', name: 'Logout', component: Logout, route: Route },
  { path: '/forget-password', name: 'Forget Password', component: ForgetPassword, route: Route },
  { path: '/register', name: 'Register', component: Register, route: Route },
  { path: '/confirm', name: 'Confirm', component: ConfirmAccount, route: Route },

  // other pages
  { path: '/projects', name: 'Projects', component: Projects, route: PrivateRoute, roles: ['Admin'], navbar: false },
  { path: '/site/:siteId/:domain/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute, roles: ['Admin'], navbar: true },
  { path: '/site/:siteId/:domain/general', name: 'General', component: General, route: PrivateRoute, roles: ['Admin'], navbar: true },
  { path: '/site/:siteId/:domain/hosting', name: 'Hosting', component: Hosting, route: PrivateRoute, roles: ['Admin'], navbar: true, exact: true },
  { path: '/site/:siteId/:domain/hosting/billing/:invoiceNo/complete', name: 'Hosting', component: Hosting, route: PrivateRoute, roles: ['Admin'], navbar: true },
  { path: '/site/:siteId/:domain/editor', name: 'Editor', component: Editor, route: PrivateRoute, roles: ['Admin'], navbar: true },
  { path: '/site/:siteId/:domain/seo', name: 'SEO', component: SEO, route: PrivateRoute, roles: ['Admin'], navbar: true },
  { path: '/site/:siteId/:domain/form', name: 'Form', component: Form, route: PrivateRoute, roles: ['Admin'], navbar: true },
  { path: '/site/:siteId/:domain/integrations', name: 'Integrations', component: Integrations, route: PrivateRoute, roles: ['Admin'], navbar: true },
  { path: '/site/builder', name: 'Builder', component: Builder, route: PrivateRoute, roles: ['Admin'], navbar: false },
  { path: '/site/startup', name: 'Startup', component: Startup, route: PrivateRoute, roles: ['Admin'], navbar: false },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/projects" />,
    route: PrivateRoute
  },
  
]

export { routes, PrivateRoute };