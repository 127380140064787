// @flow
import {
    FORM_SMTP_SETTING,
    FORM_SMTP_SETTING_SUCCESS,
    FORM_SMTP_SETTING_FAILED,
    FORM_SMTP_SETTING_SAVE
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    smtpsetting: null
};

type FormAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, smtpsetting?: {} | null, saving?: boolean};

const FormSmtpSetting = (state:State = INIT_STATE, action: FormAction) => {
    switch (action.type) {
        case FORM_SMTP_SETTING:
            return { ...state, loading: true };
        case FORM_SMTP_SETTING_SAVE:
            return { ...state, saving: true };
        case FORM_SMTP_SETTING_SUCCESS:
            return { ...state, smtpsetting: action.payload, loading: false, saving: false, error: null };
        case FORM_SMTP_SETTING_FAILED:
            return { ...state, error: action.payload, loading: false, saveing: false };
        default: return { ...state };
    }
}

export default FormSmtpSetting;