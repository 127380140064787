// @flow
import {
    HOSTING_PLAN,
    HOSTING_PLAN_SUCCESS,
    HOSTING_PLAN_FAILED,
    HOSTING_PLAN_CHARGE,
    HOSTING_PLAN_CHARGE_SUCCESS,
    HOSTING_PLAN_CHARGE_FAILED,
    HOSTING_PLAN_BILLING,
    HOSTING_PLAN_BILLING_SUCCESS,
    HOSTING_PLAN_BILLING_FAILED,
    HOSTING_PLAN_BANKTRANSFER,
    HOSTING_PLAN_BANKTRANSFER_SUCCESS,
    HOSTING_PLAN_BANKTRANSFER_FAILED
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    hosting: null,
    charge: null
};

type HostingAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, hosting?: {} | null, saving?: boolean, charge?: {} | null};

const HostingPlan = (state:State = INIT_STATE, action: HostingAction) => {
    switch (action.type) {
        case HOSTING_PLAN:
            return { ...state, loading: true };
        case HOSTING_PLAN_SUCCESS:
            return { ...state, hosting: action.payload, loading: false, saving: false, error: null };
        case HOSTING_PLAN_FAILED:
            return { ...state, error: action.payload, loading: false, saveing: false };
        case HOSTING_PLAN_CHARGE:
            return { ...state, saving: true, charge: null };
        case HOSTING_PLAN_CHARGE_SUCCESS:
            return { ...state, charge: action.payload, saving: false, error: null };
        case HOSTING_PLAN_CHARGE_FAILED:
            return { ...state, error: action.payload, saving: false };
        case HOSTING_PLAN_BILLING:
            return { ...state, saving: true };
        case HOSTING_PLAN_BILLING_SUCCESS:
            return { ...state, hosting: action.payload, saving: false, error: null };
        case HOSTING_PLAN_BILLING_FAILED:
            return { ...state, error: action.payload, saving: false };
        case HOSTING_PLAN_BANKTRANSFER:
            return { ...state, saving: true };
        case HOSTING_PLAN_BANKTRANSFER_SUCCESS:
            return { ...state, hosting: action.payload, saving: false, error: null };
        case HOSTING_PLAN_BANKTRANSFER_FAILED:
            return { ...state, error: action.payload, saving: false };
        default: return { ...state };
    }
}

export default HostingPlan;