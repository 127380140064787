// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    PARTNER_VERIFY,
    PARTNER_VERIFY_SUCCESS,
    PARTNER_VERIFY_FAILED
} from '../../constants/actionTypes';

type AuthAction = { type: string, payload: {} | string };

export const loginUser = (username: string, password: string, partner_id: string, partner_brand: string, partner_system_name: string, partner_domain: string, partner_backend_url: string, partner_help_url: string, partner_allow_crete_project: int): AuthAction => ({
    type: LOGIN_USER,
    payload: { username, password, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_allow_crete_project }
});

export const loginUserSuccess = (user: string): AuthAction => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const loginUserFailed = (error: string): AuthAction => ({
    type: LOGIN_USER_FAILED,
    payload: error
});

export const registerUser = (fullname: string, email: string, password: string, tel: string, partner_id: string, partner_brand: string, partner_system_name: string, partner_domain: string, partner_backend_url: string, partner_help_url: string, partner_allow_crete_project: int): AuthAction => ({
    type: REGISTER_USER,
    payload: { fullname, email, password, tel, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_allow_crete_project }
});

export const registerUserSuccess = (user: {}): AuthAction => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const registerUserFailed = (error: string): AuthAction => ({
    type: REGISTER_USER_FAILED,
    payload: error
});

export const logoutUser = (history: any): AuthAction => ({
    type: LOGOUT_USER,
    payload: { history }
});

export const forgetPassword = (username: string): AuthAction => ({
    type: FORGET_PASSWORD,
    payload: { username }
});

export const forgetPasswordSuccess = (passwordResetStatus: string): AuthAction => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus
});

export const forgetPasswordFailed = (error: string): AuthAction => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error
});

export const partnerVerify = (hostname: string): AuthAction => ({
    type: PARTNER_VERIFY,
    payload: { hostname }
});

export const partnerVerifySuccess = (partner: string): AuthAction => ({
    type: PARTNER_VERIFY_SUCCESS,
    payload: partner
});

export const partnerVerifyFailed = (error: string): AuthAction => ({
    type: PARTNER_VERIFY_FAILED,
    payload: error
});