// @flow
import {
    FORM_CAPTCHA_SETTING,
    FORM_CAPTCHA_SETTING_SAVE,
    FORM_CAPTCHA_SETTING_SUCCESS,
    FORM_CAPTCHA_SETTING_FAILED
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    captcha: null
};

type FormAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, captcha?: {} | null, saving?: boolean};

const FormCaptChaSetting = (state:State = INIT_STATE, action: FormAction) => {
    switch (action.type) {
        case FORM_CAPTCHA_SETTING:
            return { ...state, loading: true };
        case FORM_CAPTCHA_SETTING_SAVE:
            return { ...state, saving: true };
        case FORM_CAPTCHA_SETTING_SUCCESS:
            return { ...state, captcha: action.payload, loading: false, saving: false, error: null };
        case FORM_CAPTCHA_SETTING_FAILED:
            return { ...state, error: action.payload, loading: false, saveing: false };
        default: return { ...state };
    }
}

export default FormCaptChaSetting;