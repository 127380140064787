// @flow
import {
    SEO_GOOGLE_VERIFY,
    SEO_GOOGLE_VERIFY_SUCCESS,
    SEO_GOOGLE_VERIFY_FAILED,
    SEO_GOOGLE_VERIFY_SAVE
} from '../../constants/actionTypes';

type SeoAction = { type: string, payload: {} | string };

export const seoGoogleVerify = (site_id: string, token: string): SeoAction => ({
    type: SEO_GOOGLE_VERIFY,
    payload: {site_id, token}
});

export const seoGoogleVerifySuccess = (googleverify: string): SeoAction => ({
    type: SEO_GOOGLE_VERIFY_SUCCESS,
    payload: googleverify
});

export const seoGoogleVerifyFailed = (error: string): SeoAction => ({
    type: SEO_GOOGLE_VERIFY_FAILED,
    payload: error
});

export const seoGoogleVerifySave = (site_id: string, data: string, token: string): SeoAction => ({
    type: SEO_GOOGLE_VERIFY_SAVE,
    payload: {site_id, data, token}
});
