// @flow
import {
    DASHBOARD_DISK_USAGE,
    DASHBOARD_DISK_USAGE_SUCCESS,
    DASHBOARD_DISK_USAGE_FAILED,
    DASHBOARD_SUMMARY,
    DASHBOARD_SUMMARY_SUCCESS,
    DASHBOARD_SUMMARY_FAILED,
    DASHBOARD_USERONLINE,
    DASHBOARD_USERONLINE_SUCCESS,
    DASHBOARD_USERONLINE_FAILED,
    DASHBOARD_HITS,
    DASHBOARD_HITS_SUCCESS,
    DASHBOARD_HITS_FAILED,
    DASHBOARD_BROWSER,
    DASHBOARD_BROWSER_SUCCESS,
    DASHBOARD_BROWSER_FAILED,
    DASHBOARD_PLATFORM,
    DASHBOARD_PLATFORM_SUCCESS,
    DASHBOARD_PLATFORM_FAILED
} from '../../constants/actionTypes';

type DashboardAction = { type: string, payload: {} | string };

export const dashboardDiskUsage = (site_id: string, token: string): DashboardAction => ({
    type: DASHBOARD_DISK_USAGE,
    payload: {site_id, token}
});

export const dashboardDiskUsageSuccess = (diskusage: string): DashboardAction => ({
    type: DASHBOARD_DISK_USAGE_SUCCESS,
    payload: diskusage
});

export const dashboardDiskUsageFailed = (error: string): DashboardAction => ({
    type: DASHBOARD_DISK_USAGE_FAILED,
    payload: error
});

export const dashboardSummary = (site_id: string, token: string): DashboardAction => ({
    type: DASHBOARD_SUMMARY,
    payload: {site_id, token}
});

export const dashboardSummarySuccess = (summary: string): DashboardAction => ({
    type: DASHBOARD_SUMMARY_SUCCESS,
    payload: summary
});

export const dashboardSummaryFailed = (error: string): DashboardAction => ({
    type: DASHBOARD_SUMMARY_FAILED,
    payload: error
});

export const dashboardUserOnline = (site_id: string, token: string): DashboardAction => ({
    type: DASHBOARD_USERONLINE,
    payload: {site_id, token}
});

export const dashboardUserOnlineSuccess = (useronline: string): DashboardAction => ({
    type: DASHBOARD_USERONLINE_SUCCESS,
    payload: useronline
});

export const dashboardUserOnlineFailed = (error: string): DashboardAction => ({
    type: DASHBOARD_USERONLINE_FAILED,
    payload: error
});

export const dashboardHits = (site_id: string, token: string): DashboardAction => ({
    type: DASHBOARD_HITS,
    payload: {site_id, token}
});

export const dashboardHitsSuccess = (hits: string): DashboardAction => ({
    type: DASHBOARD_HITS_SUCCESS,
    payload: hits
});

export const dashboardHitsFailed = (error: string): DashboardAction => ({
    type: DASHBOARD_HITS_FAILED,
    payload: error
});

export const dashboardBrowser = (site_id: string, token: string): DashboardAction => ({
    type: DASHBOARD_BROWSER,
    payload: {site_id, token}
});

export const dashboardBrowserSuccess = (hits: string): DashboardAction => ({
    type: DASHBOARD_BROWSER_SUCCESS,
    payload: hits
});

export const dashboardBrowserFailed = (error: string): DashboardAction => ({
    type: DASHBOARD_BROWSER_FAILED,
    payload: error
});

export const dashboardPlatform = (site_id: string, token: string): DashboardAction => ({
    type: DASHBOARD_PLATFORM,
    payload: {site_id, token}
});

export const dashboardPlatformSuccess = (hits: string): DashboardAction => ({
    type: DASHBOARD_PLATFORM_SUCCESS,
    payload: hits
});

export const dashboardPlatformFailed = (error: string): DashboardAction => ({
    type: DASHBOARD_PLATFORM_FAILED,
    payload: error
});