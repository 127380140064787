// @flow
import {
    INTEGRATIONS_LINENOTIFY,
    INTEGRATIONS_LINENOTIFY_SUCCESS,
    INTEGRATIONS_LINENOTIFY_FAILED,
    INTEGRATIONS_LINENOTIFY_SAVE
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    linenotify: null
};

type IntegrationsAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, linenotify?: {} | null, saving?: boolean};

const IntegrationsLineNotify = (state:State = INIT_STATE, action: IntegrationsAction) => {
    switch (action.type) {
        case INTEGRATIONS_LINENOTIFY:
            return { ...state, loading: true };
        case INTEGRATIONS_LINENOTIFY_SAVE:
            return { ...state, saving: true };
        case INTEGRATIONS_LINENOTIFY_SUCCESS:
            return { ...state, linenotify: action.payload, loading: false, saving: false, error: null };
        case INTEGRATIONS_LINENOTIFY_FAILED:
            return { ...state, error: action.payload, loading: false, saveing: false };
        default: return { ...state };
    }
}

export default IntegrationsLineNotify;