// @flow
import {
    DASHBOARD_SUMMARY,
    DASHBOARD_SUMMARY_SUCCESS,
    DASHBOARD_SUMMARY_FAILED
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    summary: null
};

type DashboardAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, summary?: {} | null};

const DashboardSummary = (state:State = INIT_STATE, action: DashboardAction) => {
    switch (action.type) {
        case DASHBOARD_SUMMARY:
            return { ...state, loading: true };
        case DASHBOARD_SUMMARY_SUCCESS:
            return { ...state, summary: action.payload, loading: false, error: null };
        case DASHBOARD_SUMMARY_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default DashboardSummary;