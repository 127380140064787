// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    EDITOR_COLLABORATORS,
    EDITOR_COLLABORATORS_SAVE,
    EDITOR_COLLABORATORS_DELETE,
    EDITOR_TRANSFER_OWNER
} from '../../constants/actionTypes';


import {
    editorCollaboratorsSuccess,
    editorCollaboratorsFailed
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


function* editorcollaborators({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/contributor`, options);
        if(response['status']){
            yield put(editorCollaboratorsSuccess(response['data']));
        }
        else{
            yield put(editorCollaboratorsFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(editorCollaboratorsFailed(message));
    }
}

function* editorcollaboratorssave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/contributor`, options);
        if(response['status']){
            yield put(editorCollaboratorsSuccess(response['data']));
        }
        else{
            yield put(editorCollaboratorsFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(editorCollaboratorsFailed(message));
    }
}

function* editorcollaboratorsdelete({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/contributor`, options);
        if(response['status']){
            yield put(editorCollaboratorsSuccess(response['data']));
        }
        else{
            yield put(editorCollaboratorsFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(editorCollaboratorsFailed(message));
    }
}

function* editortransferowner({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/transferowner`, options);
        if(response['status']){
            yield put(editorCollaboratorsSuccess(response['data']));
        }
        else{
            yield put(editorCollaboratorsFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(editorCollaboratorsFailed(message));
    }
}

export function* watchEditorCollaborators():any {
    yield takeEvery(EDITOR_COLLABORATORS, editorcollaborators);
}

export function* watchEditorCollaboratorsSave():any {
    yield takeEvery(EDITOR_COLLABORATORS_SAVE, editorcollaboratorssave);
}

export function* watchEditorCollaboratorsDelete():any {
    yield takeEvery(EDITOR_COLLABORATORS_DELETE, editorcollaboratorsdelete);
}

export function* watchEditorTransferOwner():any {
    yield takeEvery(EDITOR_TRANSFER_OWNER, editortransferowner);
}

function* editorSaga():any {
    yield all([
        fork(watchEditorCollaborators),
        fork(watchEditorCollaboratorsSave),
        fork(watchEditorCollaboratorsDelete),
        fork(watchEditorTransferOwner),
    ]);
}

export default editorSaga;