// @flow
import {
    GENERAL_BUSINESS_INFO,
    GENERAL_BUSINESS_INFO_SUCCESS,
    GENERAL_BUSINESS_INFO_FAILED,
    GENERAL_BUSINESS_INFO_SAVE
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    businessinfo: null
};

type GeneralAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, businessinfo?: {} | null, saving?: boolean};

const GeneralBusinessInfo = (state:State = INIT_STATE, action: GeneralAction) => {
    switch (action.type) {
        case GENERAL_BUSINESS_INFO:
            return { ...state, loading: true };
        case GENERAL_BUSINESS_INFO_SAVE:
            return { ...state, saving: true };
        case GENERAL_BUSINESS_INFO_SUCCESS:
            return { ...state, businessinfo: action.payload, loading: false, saving: false, error: null };
        case GENERAL_BUSINESS_INFO_FAILED:
            return { ...state, error: action.payload, loading: false, saveing: false };
        default: return { ...state };
    }
}

export default GeneralBusinessInfo;