// @flow
import {
    EDITOR_COLLABORATORS,
    EDITOR_COLLABORATORS_SUCCESS,
    EDITOR_COLLABORATORS_FAILED,
    EDITOR_COLLABORATORS_SAVE,
    EDITOR_COLLABORATORS_DELETE,
    EDITOR_TRANSFER_OWNER
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    collaborators: null
};

type EditorAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, collaborators?: {} | null, saving?: boolean, transfer?: boolean};

const EditorCollaborators = (state:State = INIT_STATE, action: EditorAction) => {
    switch (action.type) {
        case EDITOR_COLLABORATORS:
            return { ...state, loading: true };
        case EDITOR_COLLABORATORS_SAVE:
            return { ...state, saving: true };
        case EDITOR_COLLABORATORS_DELETE:
            return { ...state, saving: true };
        case EDITOR_TRANSFER_OWNER:
            return { ...state, transfer: true };
        case EDITOR_COLLABORATORS_SUCCESS:
            return { ...state, collaborators: action.payload, loading: false, saving: false, transfer: false, error: null };
        case EDITOR_COLLABORATORS_FAILED:
            return { ...state, error: action.payload, loading: false, saveing: false, transfer: false };
        default: return { ...state };
    }
}

export default EditorCollaborators;