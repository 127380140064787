// @flow
import {
    HOSTING_PLAN_PUBLISH,
    HOSTING_PLAN_PUBLISH_SUCCESS,
    HOSTING_PLAN_PUBLISH_FAILED,
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    publishsite: null
};

type HostingAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, publishsite?: {} | null};

const HostingPlanPublish = (state:State = INIT_STATE, action: HostingAction) => {
    switch (action.type) {
        case HOSTING_PLAN_PUBLISH:
            return { ...state, loading: true };
        case HOSTING_PLAN_PUBLISH_SUCCESS:
            return { ...state, publishsite: action.payload, loading: false, error: null };
        case HOSTING_PLAN_PUBLISH_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default HostingPlanPublish;