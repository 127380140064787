// @flow
import {
    SITE_LIST,
    SITE_LIST_SUCCESS,
    SITE_LIST_FAILED,
    SITE_SCREENSHOT,
    SITE_SCREENSHOT_SUCCESS,
    SITE_SCREENSHOT_FAILED,
    SITE_CLEAR,
    SITE_BUILDER,
    SITE_BUILDER_SUCCESS,
    SITE_BUILDER_FAILED,
    SITE_ACCESS_TOKEN,
    SITE_ACCESS_TOKEN_SUCCESS,
    SITE_ACCESS_TOKEN_FAILED
} from '../../constants/actionTypes';

type SiteAction = { type: string, payload: {} | string };

export const siteList = (token: string): SiteAction => ({
    type: SITE_LIST,
    payload: {token}
});

export const siteListSuccess = (sites: string): SiteAction => ({
    type: SITE_LIST_SUCCESS,
    payload: sites
});

export const siteListFailed = (error: string): SiteAction => ({
    type: SITE_LIST_FAILED,
    payload: error
});
export const siteScreenShot = (siteid: string, token: string): SiteAction => ({
    type: SITE_SCREENSHOT,
    payload: {siteid,token}
});

export const siteScreenShotSuccess = (site: string): SiteAction => ({
    type: SITE_SCREENSHOT_SUCCESS,
    payload: site
});

export const siteScreenShotFailed = (error: string): SiteAction => ({
    type: SITE_SCREENSHOT_FAILED,
    payload: error
});

export const siteClear = (): SiteAction => ({
    type: SITE_CLEAR
});

export const siteBuilder = (projectname: string, pagebuilder: string, partner_id: string, token: string): SiteAction => ({
    type: SITE_BUILDER,
    payload: {projectname,pagebuilder,partner_id,token}
});

export const siteBuilderSuccess = (sites: string): SiteAction => ({
    type: SITE_BUILDER_SUCCESS,
    payload: sites
});

export const siteBuilderFailed = (error: string): SiteAction => ({
    type: SITE_BUILDER_FAILED,
    payload: error
});

export const siteAccessToken = (siteid: string, username: string, method: string, token: string): SiteAction => ({
    type: SITE_ACCESS_TOKEN,
    payload: {siteid,username,method,token}
});

export const siteAccessTokenSuccess = (accesstoken: string): SiteAction => ({
    type: SITE_ACCESS_TOKEN_SUCCESS,
    payload: accesstoken
});

export const siteAccessTokenFailed = (error: string): SiteAction => ({
    type: SITE_ACCESS_TOKEN_FAILED,
    payload: error
});