// @flow
import {
    FORM_EMAIL_SETTING,
    FORM_EMAIL_SETTING_SUCCESS,
    FORM_EMAIL_SETTING_FAILED,
    FORM_EMAIL_SETTING_SAVE,
    FORM_SMTP_SETTING,
    FORM_SMTP_SETTING_SAVE,
    FORM_SMTP_SETTING_SUCCESS,
    FORM_SMTP_SETTING_FAILED,
    FORM_CAPTCHA_SETTING,
    FORM_CAPTCHA_SETTING_SAVE,
    FORM_CAPTCHA_SETTING_SUCCESS,
    FORM_CAPTCHA_SETTING_FAILED
} from '../../constants/actionTypes';

type FormAction = { type: string, payload: {} | string };

export const formEmailSetting = (site_id: string, token: string): FormAction => ({
    type: FORM_EMAIL_SETTING,
    payload: {site_id, token}
});

export const formEmailSettingSuccess = (emailsetting: string): FormAction => ({
    type: FORM_EMAIL_SETTING_SUCCESS,
    payload: emailsetting
});

export const formEmailSettingFailed = (error: string): FormAction => ({
    type: FORM_EMAIL_SETTING_FAILED,
    payload: error
});

export const formEmailSettingSave = (site_id: string, data: string, token: string): FormAction => ({
    type: FORM_EMAIL_SETTING_SAVE,
    payload: {site_id, data, token}
});

export const formSmtpSetting = (site_id: string, token: string): FormAction => ({
    type: FORM_SMTP_SETTING,
    payload: {site_id, token}
});

export const formSmtpSettingSuccess = (smtpsetting: string): FormAction => ({
    type: FORM_SMTP_SETTING_SUCCESS,
    payload: smtpsetting
});

export const formSmtpSettingFailed = (error: string): FormAction => ({
    type: FORM_SMTP_SETTING_FAILED,
    payload: error
});

export const formSmtpSettingSave = (site_id: string, data: string, token: string): FormAction => ({
    type: FORM_SMTP_SETTING_SAVE,
    payload: {site_id, data, token}
});

export const formCaptChaSetting = (site_id: string, token: string): FormAction => ({
    type: FORM_CAPTCHA_SETTING,
    payload: {site_id, token}
});

export const formCaptChaSettingSuccess = (captcha: string): FormAction => ({
    type: FORM_CAPTCHA_SETTING_SUCCESS,
    payload: captcha
});

export const formCaptChaSettingFailed = (error: string): FormAction => ({
    type: FORM_CAPTCHA_SETTING_FAILED,
    payload: error
});

export const formCaptChaSettingSave = (site_id: string, data: string, token: string): FormAction => ({
    type: FORM_CAPTCHA_SETTING_SAVE,
    payload: {site_id, data, token}
});
