// @flow
import {
    SEO_GOOGLE_VERIFY,
    SEO_GOOGLE_VERIFY_SUCCESS,
    SEO_GOOGLE_VERIFY_FAILED,
    SEO_GOOGLE_VERIFY_SAVE
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    googleverify: null
};

type SeoAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, googleverify?: {} | null, saving?: boolean};

const SeoGoogleVerify = (state:State = INIT_STATE, action: SeoAction) => {
    switch (action.type) {
        case SEO_GOOGLE_VERIFY:
            return { ...state, loading: true };
        case SEO_GOOGLE_VERIFY_SAVE:
            return { ...state, saving: true };
        case SEO_GOOGLE_VERIFY_SUCCESS:
            return { ...state, googleverify: action.payload, loading: false, saving: false, error: null };
        case SEO_GOOGLE_VERIFY_FAILED:
            return { ...state, error: action.payload, loading: false, saveing: false };
        default: return { ...state };
    }
}

export default SeoGoogleVerify;