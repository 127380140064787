// @flow
import {
    GENERAL_BASIC_INFO,
    GENERAL_BASIC_INFO_SUCCESS,
    GENERAL_BASIC_INFO_FAILED,
    GENERAL_BASIC_INFO_SAVE,
    GENERAL_CONTACT_INFO,
    GENERAL_CONTACT_INFO_SAVE,
    GENERAL_CONTACT_INFO_SUCCESS,
    GENERAL_CONTACT_INFO_FAILED,
    GENERAL_BUSINESS_INFO,
    GENERAL_BUSINESS_INFO_SAVE,
    GENERAL_BUSINESS_INFO_SUCCESS,
    GENERAL_BUSINESS_INFO_FAILED,
    GENERAL_REGIONAL,
    GENERAL_REGIONAL_SAVE,
    GENERAL_REGIONAL_SUCCESS,
    GENERAL_REGIONAL_FAILED
} from '../../constants/actionTypes';

type GeneralAction = { type: string, payload: {} | string };

export const generalBasicInfo = (site_id: string, token: string): GeneralAction => ({
    type: GENERAL_BASIC_INFO,
    payload: {site_id, token}
});

export const generalBasicInfoSuccess = (basicinfo: string): GeneralAction => ({
    type: GENERAL_BASIC_INFO_SUCCESS,
    payload: basicinfo
});

export const generalBasicInfoFailed = (error: string): GeneralAction => ({
    type: GENERAL_BASIC_INFO_FAILED,
    payload: error
});

export const generalBasicInfoSave = (site_id: string, data: string, token: string): GeneralAction => ({
    type: GENERAL_BASIC_INFO_SAVE,
    payload: {site_id, data, token}
});

export const generalContactInfo = (site_id: string, token: string): GeneralAction => ({
    type: GENERAL_CONTACT_INFO,
    payload: {site_id, token}
});

export const generalContactInfoSuccess = (contactinfo: string): GeneralAction => ({
    type: GENERAL_CONTACT_INFO_SUCCESS,
    payload: contactinfo
});

export const generalContactInfoFailed = (error: string): GeneralAction => ({
    type: GENERAL_CONTACT_INFO_FAILED,
    payload: error
});

export const generalContactInfoSave = (site_id: string, data: string, token: string): GeneralAction => ({
    type: GENERAL_CONTACT_INFO_SAVE,
    payload: {site_id, data, token}
});

export const generalBusinessInfo = (site_id: string, token: string): GeneralAction => ({
    type: GENERAL_BUSINESS_INFO,
    payload: {site_id, token}
});

export const generalBusinessInfoSuccess = (businessinfo: string): GeneralAction => ({
    type: GENERAL_BUSINESS_INFO_SUCCESS,
    payload: businessinfo
});

export const generalBusinessInfoFailed = (error: string): GeneralAction => ({
    type: GENERAL_BUSINESS_INFO_FAILED,
    payload: error
});

export const generalBusinessInfoSave = (site_id: string, data: string, token: string): GeneralAction => ({
    type: GENERAL_BUSINESS_INFO_SAVE,
    payload: {site_id, data, token}
});

export const generalRegional = (site_id: string, token: string): GeneralAction => ({
    type: GENERAL_REGIONAL,
    payload: {site_id, token}
});

export const generalRegionalSuccess = (regional: string): GeneralAction => ({
    type: GENERAL_REGIONAL_SUCCESS,
    payload: regional
});

export const generalRegionalFailed = (error: string): GeneralAction => ({
    type: GENERAL_REGIONAL_FAILED,
    payload: error
});

export const generalRegionalSave = (site_id: string, data: string, token: string): GeneralAction => ({
    type: GENERAL_REGIONAL_SAVE,
    payload: {site_id, data, token}
});
