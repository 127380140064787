// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    HOSTING_PLAN,
    HOSTING_PLAN_CHARGE,
    HOSTING_PLAN_BILLING,
    HOSTING_PLAN_DNS,
    HOSTING_PLAN_CHECKDOMAIN,
    HOSTING_PLAN_PUBLISH,
    HOSTING_PLAN_BANKTRANSFER
} from '../../constants/actionTypes';


import {
    hostingPlanSuccess,
    hostingPlanFailed,
    hostingPlanChargeSuccess,
    hostingPlanChargeFailed,
    hostingPlanBillingSuccess,
    hostingPlanBillingFailed,
    hostingPlanDNSSuccess,
    hostingPlanDNSFailed,
    hostingPlanCheckDomainSuccess,
    hostingPlanCheckDomainFailed,
    hostingPlanPublishSuccess,
    hostingPlanPublishFailed,
    hostingPlanBankTransferSuccess,
    hostingPlanBankTransferFailed
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


function* hostingplan({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/hosting`, options);
        if(response['status']){
            yield put(hostingPlanSuccess(response['data']));
        }
        else{
            yield put(hostingPlanFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(hostingPlanFailed(message));
    }
}

function* hostingplancharge({ payload: { site_id, site_plan, start_date, expiry_date, amount, currency, card, return_uri, accessToken, token } }) {
    const options = {
        body: JSON.stringify({ site_plan, start_date, expiry_date, amount, currency, card, return_uri, accessToken }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/hosting/billing`, options);
        if(response['status']){
            yield put(hostingPlanChargeSuccess(response['data']));
        }
        else{
            yield put(hostingPlanChargeFailed(response['message']));
            
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(hostingPlanChargeFailed(message));
    }
}

function* hostingplanbilling({ payload: { site_id, invoice_no, accessToken, token } }) {
    const options = {
        body: JSON.stringify({ accessToken }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/hosting/billing/${invoice_no}/complete`, options);
        if(response['status']){
            yield put(hostingPlanBillingSuccess(response['data']));
        }
        else{
            yield put(hostingPlanBillingFailed(response['message']));
            
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(hostingPlanBillingFailed(message));
    }
}

function* hostingplandns({ payload: { site_id, domain_name, domain_type, domain_available, ip_type_a, cname_www, token } }) {
    const options = {
        body: JSON.stringify({ domain_name, domain_type, domain_available, ip_type_a, cname_www }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/checkdns`, options);
        if(response['status']){
            yield put(hostingPlanDNSSuccess(response['data']));
        }
        else{
            yield put(hostingPlanDNSFailed(response['message']));
            
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(hostingPlanDNSFailed(message));
    }
}

function* hostingplancheckdomain({ payload: { site_id, domain_name, token } }) {
    const options = {
        body: JSON.stringify({ domain_name }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/checkdomain`, options);
        if(response['status']){
            yield put(hostingPlanCheckDomainSuccess(response['data']));
        }
        else{
            yield put(hostingPlanCheckDomainFailed(response['message']));
            
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(hostingPlanCheckDomainFailed(message));
    }
}

function* hostingplanpublish({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/publish`, options);
        if(response['status']){
            yield put(hostingPlanPublishSuccess(response['data']));
        }
        else{
            yield put(hostingPlanPublishFailed(response['message']));
            
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(hostingPlanPublishFailed(message));
    }
}

function* hostingplanbanktransfer({ payload: { site_id, site_plan, start_date, expiry_date, token } }) {
    const options = {
        body: JSON.stringify({ site_plan, start_date, expiry_date }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/hosting/banktransfer`, options);
        if(response['status']){
            yield put(hostingPlanBankTransferSuccess(response['data']));
        }
        else{
            yield put(hostingPlanBankTransferFailed(response['message']));
            
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(hostingPlanBankTransferFailed(message));
    }
}

export function* watchHostingPlan():any {
    yield takeEvery(HOSTING_PLAN, hostingplan);
}

export function* watchHostingPlanCharge():any {
    yield takeEvery(HOSTING_PLAN_CHARGE, hostingplancharge);
}

export function* watchHostingPlanBilling():any {
    yield takeEvery(HOSTING_PLAN_BILLING, hostingplanbilling);
}

export function* watchHostingPlanDNS():any {
    yield takeEvery(HOSTING_PLAN_DNS, hostingplandns);
}

export function* watchHostingPlanCheckDomain():any {
    yield takeEvery(HOSTING_PLAN_CHECKDOMAIN, hostingplancheckdomain);
}

export function* watchHostingPlanPublish():any {
    yield takeEvery(HOSTING_PLAN_PUBLISH, hostingplanpublish);
}

export function* watchHostingPlanBankTransfer():any {
    yield takeEvery(HOSTING_PLAN_BANKTRANSFER, hostingplanbanktransfer);
}


function* hostingSaga():any {
    yield all([
        fork(watchHostingPlan),
        fork(watchHostingPlanCharge),
        fork(watchHostingPlanBilling),
        fork(watchHostingPlanDNS),
        fork(watchHostingPlanCheckDomain),
        fork(watchHostingPlanPublish),
        fork(watchHostingPlanBankTransfer)
    ]);
}

export default hostingSaga;