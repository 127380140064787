// @flow
import {
    DASHBOARD_USERONLINE,
    DASHBOARD_USERONLINE_SUCCESS,
    DASHBOARD_USERONLINE_FAILED
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    useronline: null
};

type DashboardAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, useronline?: {} | null};

const DashboardUserOnline = (state:State = INIT_STATE, action: DashboardAction) => {
    switch (action.type) {
        case DASHBOARD_USERONLINE:
            return { ...state, loading: true };
        case DASHBOARD_USERONLINE_SUCCESS:
            return { ...state, useronline: action.payload, loading: false, error: null };
        case DASHBOARD_USERONLINE_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default DashboardUserOnline;