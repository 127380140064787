// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    FORGET_PASSWORD,
    PARTNER_VERIFY
} from '../../constants/actionTypes';


import {
    loginUserSuccess,
    loginUserFailed,
    //registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
    partnerVerifySuccess,
    partnerVerifyFailed
} from './actions';

//import { siteClear } from '../site/actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


/**
 * Sets the session
 * @param {*} user 
 */
const setSession = (user) => {
    let cookies = new Cookies();
    if (user){
        cookies.set("user", JSON.stringify(user), { path: "/" });
    }
    else{
        cookies.remove("user");
    }
};
/**
 * Login the user
 * @param {*} payload - username and password 
 */
function* login({ payload: { username, password, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_allow_crete_project } }) {
    const options = {
        body: JSON.stringify({ username, password,  partner_id}),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey }
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/users/authenticate`, options);
        if(response['status']){
            let responseData = response['data'];
            let responseJson = {
                id: responseData['ID'],
                username: responseData['user_email'],
                fullname: responseData['display_name'],
                user_type: responseData['user_type'],
                role: "Admin",
                token: response['token'],
                partner_id: partner_id,
                partner_brand: partner_brand,
                partner_system_name: partner_system_name,
                partner_domain: partner_domain,
                partner_backend_url: partner_backend_url,
                partner_help_url: partner_help_url,
                partner_allow_crete_project: partner_allow_crete_project
            };

            setSession(JSON.stringify(responseJson));
            yield put(loginUserSuccess(JSON.stringify(responseJson)));
        }
        else{
            yield put(loginUserFailed(response['message']));
            setSession(null);
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}


/**
 * Logout the user
 * @param {*} param0 
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        //yield put(siteClear());
        
        yield call(() => {
            history.push("/login");
        });
    } catch (error) { }
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password, tel, partner_id, partner_brand, partner_system_name, partner_domain, partner_backend_url, partner_help_url, partner_allow_crete_project } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password, tel, partner_id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey  }
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/users/register`, options);
        if(response['status']){
            let responseData = response['data'];
            let responseJson = {
                id: responseData['ID'],
                username: responseData['user_email'],
                fullname: responseData['display_name'],
                user_type: responseData['user_type'],
                role: "Admin",
                token: response['token'],
                partner_id: partner_id,
                partner_brand: partner_brand,
                partner_system_name: partner_system_name,
                partner_domain: partner_domain,
                partner_backend_url: partner_backend_url,
                partner_help_url: partner_help_url,
                partner_allow_crete_project: partner_allow_crete_project
            };

            setSession(JSON.stringify(responseJson));
            yield put(loginUserSuccess(JSON.stringify(responseJson)));

            //yield put(registerUserSuccess(response));
        }
        else{
            yield put(registerUserFailed(response['message']));
            setSession(null);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(registerUserFailed(message));
        setSession(null);
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ username }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

function* partnerVerify({ payload: { hostname } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': myConfig.partnerToken}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/users/${hostname}`, options);
        if(response['status']){
            yield put(partnerVerifySuccess(response['data']));
        }
        else{
            yield put(partnerVerifyFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(partnerVerifyFailed(message));
    }
}


export function* watchLoginUser():any {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser():any {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser():any {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword():any {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchPartnerVerify():any {
    yield takeEvery(PARTNER_VERIFY, partnerVerify);
}

function* authSaga():any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
        fork(watchPartnerVerify)
    ]);
}

export default authSaga;