// @flow
import {
    HOSTING_PLAN,
    HOSTING_PLAN_SUCCESS,
    HOSTING_PLAN_FAILED,
    HOSTING_PLAN_CHARGE,
    HOSTING_PLAN_CHARGE_SUCCESS,
    HOSTING_PLAN_CHARGE_FAILED,
    HOSTING_PLAN_BILLING,
    HOSTING_PLAN_BILLING_SUCCESS,
    HOSTING_PLAN_BILLING_FAILED,
    HOSTING_PLAN_DNS,
    HOSTING_PLAN_DNS_SUCCESS,
    HOSTING_PLAN_DNS_FAILED,
    HOSTING_PLAN_CHECKDOMAIN,
    HOSTING_PLAN_CHECKDOMAIN_SUCCESS,
    HOSTING_PLAN_CHECKDOMAIN_FAILED,
    HOSTING_PLAN_PUBLISH,
    HOSTING_PLAN_PUBLISH_SUCCESS,
    HOSTING_PLAN_PUBLISH_FAILED,
    HOSTING_PLAN_BANKTRANSFER,
    HOSTING_PLAN_BANKTRANSFER_SUCCESS,
    HOSTING_PLAN_BANKTRANSFER_FAILED
} from '../../constants/actionTypes';

type HostingAction = { type: string, payload: {} | string };

export const hostingPlan = (site_id: string, token: string): HostingAction => ({
    type: HOSTING_PLAN,
    payload: {site_id, token}
});

export const hostingPlanSuccess = (hosting: string): HostingAction => ({
    type: HOSTING_PLAN_SUCCESS,
    payload: hosting
});

export const hostingPlanFailed = (error: string): HostingAction => ({
    type: HOSTING_PLAN_FAILED,
    payload: error
});

export const hostingPlanCharge = (site_id: string, site_plan: String, start_date: String, expiry_date: String, amount: string, currency: string, card: string, return_uri : string, accessToken: string, token: string): HostingAction => ({
    type: HOSTING_PLAN_CHARGE,
    payload: {site_id, site_plan, start_date, expiry_date, amount, currency, card, return_uri, accessToken, token}
});

export const hostingPlanChargeSuccess = (charge: string): HostingAction => ({
    type: HOSTING_PLAN_CHARGE_SUCCESS,
    payload: charge
});

export const hostingPlanChargeFailed = (error: string): HostingAction => ({
    type: HOSTING_PLAN_CHARGE_FAILED,
    payload: error
});

export const hostingPlanBilling = (site_id: string, invoice_no: String, accessToken: string, token: string): HostingAction => ({
    type: HOSTING_PLAN_BILLING,
    payload: {site_id, invoice_no, accessToken, token}
});

export const hostingPlanBillingSuccess = (hosting: string): HostingAction => ({
    type: HOSTING_PLAN_BILLING_SUCCESS,
    payload: hosting
});

export const hostingPlanBillingFailed = (error: string): HostingAction => ({
    type: HOSTING_PLAN_BILLING_FAILED,
    payload: error
});

export const hostingPlanDNS = (site_id: string, domain_name: String, domain_type: String, domain_available: String, ip_type_a: string, cname_www: string, token: string): HostingAction => ({
    type: HOSTING_PLAN_DNS,
    payload: {site_id, domain_name, domain_type, domain_available, ip_type_a, cname_www, token}
});

export const hostingPlanDNSSuccess = (dnsrecord: string): HostingAction => ({
    type: HOSTING_PLAN_DNS_SUCCESS,
    payload: dnsrecord
});

export const hostingPlanDNSFailed = (error: string): HostingAction => ({
    type: HOSTING_PLAN_DNS_FAILED,
    payload: error
});

export const hostingPlanCheckDomain = (site_id: string, domain_name: String, token: string): HostingAction => ({
    type: HOSTING_PLAN_CHECKDOMAIN,
    payload: {site_id, domain_name, token}
});

export const hostingPlanCheckDomainSuccess = (domain: string): HostingAction => ({
    type: HOSTING_PLAN_CHECKDOMAIN_SUCCESS,
    payload: domain
});

export const hostingPlanCheckDomainFailed = (error: string): HostingAction => ({
    type: HOSTING_PLAN_CHECKDOMAIN_FAILED,
    payload: error
});

export const hostingPlanPublish = (site_id: string, data: String, token: string): HostingAction => ({
    type: HOSTING_PLAN_PUBLISH,
    payload: {site_id, data, token}
});

export const hostingPlanPublishSuccess = (publishsite: string): HostingAction => ({
    type: HOSTING_PLAN_PUBLISH_SUCCESS,
    payload: publishsite
});

export const hostingPlanPublishFailed = (error: string): HostingAction => ({
    type: HOSTING_PLAN_PUBLISH_FAILED,
    payload: error
});

export const hostingPlanBankTransfer = (site_id: string, site_plan: String, start_date: String, expiry_date: String,  token: string): HostingAction => ({
    type: HOSTING_PLAN_BANKTRANSFER,
    payload: {site_id, site_plan, start_date, expiry_date, token}
});

export const hostingPlanBankTransferSuccess = (hosting: string): HostingAction => ({
    type: HOSTING_PLAN_BANKTRANSFER_SUCCESS,
    payload: hosting
});

export const hostingPlanBankTransferFailed = (error: string): HostingAction => ({
    type: HOSTING_PLAN_BANKTRANSFER_FAILED,
    payload: error
});
