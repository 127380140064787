// @flow
import {
    INTEGRATIONS_GTAG,
    INTEGRATIONS_GTAG_SUCCESS,
    INTEGRATIONS_GTAG_FAILED,
    INTEGRATIONS_GTAG_SAVE,
    INTEGRATIONS_CHATWIDGET,
    INTEGRATIONS_CHATWIDGET_SUCCESS,
    INTEGRATIONS_CHATWIDGET_FAILED,
    INTEGRATIONS_CHATWIDGET_SAVE,
    INTEGRATIONS_FBPIXEL,
    INTEGRATIONS_FBPIXEL_SUCCESS,
    INTEGRATIONS_FBPIXEL_FAILED,
    INTEGRATIONS_FBPIXEL_SAVE,
    INTEGRATIONS_LINENOTIFY,
    INTEGRATIONS_LINENOTIFY_SUCCESS,
    INTEGRATIONS_LINENOTIFY_FAILED,
    INTEGRATIONS_LINENOTIFY_SAVE
} from '../../constants/actionTypes';

type IntegrationsAction = { type: string, payload: {} | string };

export const integrationsGtag = (site_id: string, token: string): IntegrationsAction => ({
    type: INTEGRATIONS_GTAG,
    payload: {site_id, token}
});

export const integrationsGtagSuccess = (gtag: string): IntegrationsAction => ({
    type: INTEGRATIONS_GTAG_SUCCESS,
    payload: gtag
});

export const integrationsGtagFailed = (error: string): IntegrationsAction => ({
    type: INTEGRATIONS_GTAG_FAILED,
    payload: error
});

export const integrationsGtagSave = (site_id: string, data: string, token: string): IntegrationsAction => ({
    type: INTEGRATIONS_GTAG_SAVE,
    payload: {site_id, data, token}
});

export const integrationsChatWidget = (site_id: string, token: string): IntegrationsAction => ({
    type: INTEGRATIONS_CHATWIDGET,
    payload: {site_id, token}
});

export const integrationsChatWidgetSuccess = (chatwidget: string): IntegrationsAction => ({
    type: INTEGRATIONS_CHATWIDGET_SUCCESS,
    payload: chatwidget
});

export const integrationsChatWidgetFailed = (error: string): IntegrationsAction => ({
    type: INTEGRATIONS_CHATWIDGET_FAILED,
    payload: error
});

export const integrationsChatWidgetSave = (site_id: string, data: string, token: string): IntegrationsAction => ({
    type: INTEGRATIONS_CHATWIDGET_SAVE,
    payload: {site_id, data, token}
});

export const integrationsFbpixel = (site_id: string, token: string): IntegrationsAction => ({
    type: INTEGRATIONS_FBPIXEL,
    payload: {site_id, token}
});

export const integrationsFbpixelSuccess = (fbpixel: string): IntegrationsAction => ({
    type: INTEGRATIONS_FBPIXEL_SUCCESS,
    payload: fbpixel
});

export const integrationsFbpixelFailed = (error: string): IntegrationsAction => ({
    type: INTEGRATIONS_FBPIXEL_FAILED,
    payload: error
});

export const integrationsFbpixelSave = (site_id: string, data: string, token: string): IntegrationsAction => ({
    type: INTEGRATIONS_FBPIXEL_SAVE,
    payload: {site_id, data, token}
});

export const integrationsLineNotify = (site_id: string, token: string): IntegrationsAction => ({
    type: INTEGRATIONS_LINENOTIFY,
    payload: {site_id, token}
});

export const integrationsLineNotifySuccess = (linenotify: string): IntegrationsAction => ({
    type: INTEGRATIONS_LINENOTIFY_SUCCESS,
    payload: linenotify
});

export const integrationsLineNotifyFailed = (error: string): IntegrationsAction => ({
    type: INTEGRATIONS_LINENOTIFY_FAILED,
    payload: error
});

export const integrationsLineNotifySave = (site_id: string, data: string, token: string): IntegrationsAction => ({
    type: INTEGRATIONS_LINENOTIFY_SAVE,
    payload: {site_id, data, token}
});
