// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    FORM_EMAIL_SETTING,
    FORM_EMAIL_SETTING_SAVE,
    FORM_SMTP_SETTING,
    FORM_SMTP_SETTING_SAVE,
    FORM_CAPTCHA_SETTING,
    FORM_CAPTCHA_SETTING_SAVE
} from '../../constants/actionTypes';


import {
    formEmailSettingSuccess,
    formEmailSettingFailed,
    formSmtpSettingSuccess,
    formSmtpSettingFailed,
    formCaptChaSettingSuccess,
    formCaptChaSettingFailed
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}

function* formemailsetting({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/email`, options);
        if(response['status']){
            yield put(formEmailSettingSuccess(response['data']));
        }
        else{
            yield put(formEmailSettingFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(formEmailSettingFailed(message));
    }
}

function* formemailsettingsave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/email`, options);
        if(response['status']){
            yield put(formEmailSettingSuccess(response['data']));
        }
        else{
            yield put(formEmailSettingFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(formEmailSettingFailed(message));
    }
}

function* formsmtpsetting({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/smtp`, options);
        if(response['status']){
            yield put(formSmtpSettingSuccess(response['data']));
        }
        else{
            yield put(formSmtpSettingFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(formSmtpSettingFailed(message));
    }
}

function* formsmtpsettingsave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/smtp`, options);
        if(response['status']){
            yield put(formSmtpSettingSuccess(response['data']));
        }
        else{
            yield put(formSmtpSettingFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(formSmtpSettingFailed(message));
    }
}

function* formcaptchasetting({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/recaptcha`, options);
        if(response['status']){
            yield put(formCaptChaSettingSuccess(response['data']));
        }
        else{
            yield put(formCaptChaSettingFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(formCaptChaSettingFailed(message));
    }
}

function* formcaptchasettingsave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/recaptcha`, options);
        if(response['status']){
            yield put(formCaptChaSettingSuccess(response['data']));
        }
        else{
            yield put(formCaptChaSettingFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(formCaptChaSettingFailed(message));
    }
}

export function* watchFormEmailSetting():any {
    yield takeEvery(FORM_EMAIL_SETTING, formemailsetting);
}

export function* watchFormEmailSettingSave():any {
    yield takeEvery(FORM_EMAIL_SETTING_SAVE, formemailsettingsave);
}

export function* watchFormSmtpSetting():any {
    yield takeEvery(FORM_SMTP_SETTING, formsmtpsetting);
}

export function* watchFormSmtpSettingSave():any {
    yield takeEvery(FORM_SMTP_SETTING_SAVE, formsmtpsettingsave);
}

export function* watchFormCaptChaSetting():any {
    yield takeEvery(FORM_CAPTCHA_SETTING, formcaptchasetting);
}

export function* watchFormCaptChaSettingSave():any {
    yield takeEvery(FORM_CAPTCHA_SETTING_SAVE, formcaptchasettingsave);
}


function* formSaga():any {
    yield all([
        fork(watchFormEmailSetting),
        fork(watchFormEmailSettingSave),
        fork(watchFormSmtpSetting),
        fork(watchFormSmtpSettingSave),
        fork(watchFormCaptChaSetting),
        fork(watchFormCaptChaSettingSave),
    ]);
}

export default formSaga;