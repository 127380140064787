// @flow
import {
    HOSTING_PLAN_CHECKDOMAIN,
    HOSTING_PLAN_CHECKDOMAIN_SUCCESS,
    HOSTING_PLAN_CHECKDOMAIN_FAILED,
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    domain: null
};

type HostingAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, domain?: {} | null};

const HostingPlanCheckDomain = (state:State = INIT_STATE, action: HostingAction) => {
    switch (action.type) {
        case HOSTING_PLAN_CHECKDOMAIN:
            return { ...state, loading: true };
        case HOSTING_PLAN_CHECKDOMAIN_SUCCESS:
            return { ...state, domain: action.payload, loading: false, error: null };
        case HOSTING_PLAN_CHECKDOMAIN_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default HostingPlanCheckDomain;