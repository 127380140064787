// @flow
import {
    EDITOR_COLLABORATORS,
    EDITOR_COLLABORATORS_SUCCESS,
    EDITOR_COLLABORATORS_FAILED,
    EDITOR_COLLABORATORS_SAVE,
    EDITOR_COLLABORATORS_DELETE,
    EDITOR_TRANSFER_OWNER
} from '../../constants/actionTypes';

type EditorAction = { type: string, payload: {} | string };

export const editorCollaborators = (site_id: string, token: string): EditorAction => ({
    type: EDITOR_COLLABORATORS,
    payload: {site_id, token}
});

export const editorCollaboratorsSuccess = (collaborators: string): EditorAction => ({
    type: EDITOR_COLLABORATORS_SUCCESS,
    payload: collaborators
});

export const editorCollaboratorsFailed = (error: string): EditorAction => ({
    type: EDITOR_COLLABORATORS_FAILED,
    payload: error
});

export const editorCollaboratorsSave = (site_id: string, data: string, token: string): EditorAction => ({
    type: EDITOR_COLLABORATORS_SAVE,
    payload: {site_id, data, token}
});

export const editorCollaboratorsDelete = (site_id: string, data: string, token: string): EditorAction => ({
    type: EDITOR_COLLABORATORS_DELETE,
    payload: {site_id, data, token}
});

export const editorTransferOwner = (site_id: string, data: string, token: string): EditorAction => ({
    type: EDITOR_TRANSFER_OWNER,
    payload: {site_id, data, token}
});
