// @flow
import {
    HOSTING_PLAN_DNS,
    HOSTING_PLAN_DNS_SUCCESS,
    HOSTING_PLAN_DNS_FAILED,
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    dnsrecord: null
};

type HostingAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, dnsrecord?: {} | null};

const HostingPlanDNS = (state:State = INIT_STATE, action: HostingAction) => {
    switch (action.type) {
        case HOSTING_PLAN_DNS:
            return { ...state, loading: true };
        case HOSTING_PLAN_DNS_SUCCESS:
            return { ...state, dnsrecord: action.payload, loading: false, error: null };
        case HOSTING_PLAN_DNS_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default HostingPlanDNS;