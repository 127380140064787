// @flow
import {
    DASHBOARD_PLATFORM,
    DASHBOARD_PLATFORM_SUCCESS,
    DASHBOARD_PLATFORM_FAILED
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    platform: null
};

type DashboardAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, platform?: {} | null};

const DashboardPlatform = (state:State = INIT_STATE, action: DashboardAction) => {
    switch (action.type) {
        case DASHBOARD_PLATFORM:
            return { ...state, loading: true };
        case DASHBOARD_PLATFORM_SUCCESS:
            return { ...state, platform: action.payload, loading: false, error: null };
        case DASHBOARD_PLATFORM_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default DashboardPlatform;