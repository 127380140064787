// @flow
import {
    GENERAL_CONTACT_INFO,
    GENERAL_CONTACT_INFO_SUCCESS,
    GENERAL_CONTACT_INFO_FAILED,
    GENERAL_CONTACT_INFO_SAVE
} from '../../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    contactinfo: null
};

type GeneralAction = { type: string, payload: {} | string };
type State = { loading?: boolean, +value?: boolean, contactinfo?: {} | null, saving?: boolean};

const GeneralContactInfo = (state:State = INIT_STATE, action: GeneralAction) => {
    switch (action.type) {
        case GENERAL_CONTACT_INFO:
            return { ...state, loading: true };
        case GENERAL_CONTACT_INFO_SAVE:
            return { ...state, saving: true };
        case GENERAL_CONTACT_INFO_SUCCESS:
            return { ...state, contactinfo: action.payload, loading: false, saving: false, error: null };
        case GENERAL_CONTACT_INFO_FAILED:
            return { ...state, error: action.payload, loading: false, saveing: false };
        default: return { ...state };
    }
}

export default GeneralContactInfo;